<!-- @Author: ruiwang -->
<!-- @Date: 2019-12-18 16:23:58 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-05-17 18:12:10 -->

<template>
    <router-view class="webview" />
</template>

<script>
    import {Toast} from 'vant';

    export default {
        name: 'Webview',

        components: {
            // eslint-disable-next-line vue/no-unused-components
            Toast
        }
    };
</script>

<style lang="scss">
    body {
        margin: 0;

        a {
            text-decoration: none;
        }
    }

    .webview {
        font-family:
            -apple-system,
            system-ui,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            "Helvetica Neue",
            Arial,
            sans-serif;
        padding: 25px;
        background: $webview-bg;
        min-height: 100vh;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #333;
        box-sizing: border-box;

        @supports ((height: constant(safe-area-inset-bottom)) or (height: env(safe-area-inset-bottom)))
            and (-webkit-overflow-scrolling: touch) {
            padding-bottom: calc(constant(safe-area-inset-bottom) + 0.12rem);
            padding-bottom: calc(env(safe-area-inset-bottom) + 0.12rem);
        }

        img {
            max-width: 100%;
        }
    }

</style>
